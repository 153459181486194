import {
  AiFillFacebook,
  AiFillCar,
  AiFillLinkedin,
  AiFillInstagram,
} from "react-icons/ai";
import "./Footer.css";
export default function Footer() {
  return (
    //     <footer className="footer">
    //   <div className="social-icons">
    //     {/* Use a button instead of an anchor tag */}
    //     <button className="social-icon">
    //       <AiFillFacebook />
    //     </button>
    //     <button className="social-icon">
    //       <AiFillCar />
    //     </button>
    //     <button className="social-icon">
    //       <AiFillLinkedin />
    //     </button>
    //     <button className="social-icon">
    //       <AiFillInstagram />
    //     </button>
    //   </div>

    //   <div className="menu">
    //     {/* Use a button instead of an anchor tag */}
    //     <button className="menu-item">
    //       Home
    //     </button>
    //     <button className="menu-item">
    //       About
    //     </button>
    //     <button className="menu-item">
    //       Services
    //     </button>
    //     <button className="menu-item">
    //       Team
    //     </button>
    //     <button className="menu-item">
    //       Contact
    //     </button>
    //   </div>

    //   <p className="copyright">&copy;2024 Your Company | All Rights Reserved</p>
    // </footer>

   <footer className="footer">
   
      
      
        <p>Created by <b>Joker's Games</b></p>
      <p className="copyright">&copy;2024 Obd2.0 OÜ | All Rights Reserved</p>
    </footer>
  );
}
