
import AccountList from "./AccountsTableFiles/AccountList";
import Navigation from "./Navigation/Navigation";

export default function Major() {
    return (
        <div>
            <Navigation />
            <AccountList/>
        </div>
         
            
        
    )
}